<template>
  <!-- prettier-ignore -->
  <div class="suggestion">
    <Modal.Header :text="$t('product.suggestion.title', { savings })" />
    <Modal.Content>
      <the-typography
        type="bodyLargeLong"
        v-text="$t('product.suggestion.subtitle')"
      />
      <div class="suggestion-products">
        <loading-alt v-if="!ready" />
        <template v-else>
          <div class="suggestion-product">
            <the-typography
              type="heading04"
              v-text="$t('product.suggestion.selected')"
            />
            <replace-product
              disable-bullets
              :doctor="doctorName"
              :group-id="$props.groupId"
              :product-id="sortedProducts[1].productId"
              :product="sortedProducts[1]"
              @replace="addProduct($props.personId, sortedProducts[1], $props.doctor)"
            />
          </div>
          <div class="suggestion-product">
            <the-typography
              type="heading04"
              v-text="$t('product.suggestion.savings')"
            />
            <replace-product
              disable-bullets
              is-selected
              :doctor="doctorName"
              :group-id="$props.groupId"
              :product-id="sortedProducts[0].productId"
              :product="sortedProducts[0]"
              @replace="addProduct($props.personId, sortedProducts[0], $props.doctor)"
            />
          </div>
        </template>
      </div>
    </Modal.Content>

    <Modal.Footer>
      <template #secondary>
        <Form.Button
          :disabled="!ready"
          has-loading-indicator
          secondary
          :text="$t('product.suggestion.btn.no')"
          @click="addProduct($props.personId, sortedProducts[1], $props.doctor)"
        />
      </template>
      <template #primary>
        <Form.Button
          :disabled="!ready"
          has-loading-indicator
          primary
          :text="$t('product.suggestion.btn.yes')"
          @click="addProduct($props.personId, sortedProducts[0], $props.doctor)"
        />
      </template>
    </Modal.Footer>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'

import cloneDeep from 'lodash/cloneDeep'

import { SortUtil } from '@/utils/Sorting'
import { events$, loading$ } from '@/services'

import { logError } from '@/helpers/Logger'
import { getDoctorName } from '@/utils/Doctor'

import basketStore from '@/store/basket'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProductPersons from '@/hooks/useProductPersons'

import * as Form from '@/components/Form'
import * as Modal from '@/components/Modal'
import LoadingAlt from '@/components/Container/LoadingAlt'
import ReplaceProduct from '@/components/Product/Replace/ReplaceProduct'

import { EVENT_PRODUCT } from '@/config/events'

// HOOKS
const { selectProductOption } = usePersonSpecificProduct()

// INIT
const emit = defineEmits(['added'])
const props = defineProps({
  categoryId: {
    type: String,
    required: true,
  },
  doctor: {
    type: Object,
    required: true,
  },
  groupId: {
    type: String,
    required: true,
  },
  personId: {
    type: String,
    required: true,
  },
  product: {
    type: Object,
    required: true,
  },
  source: {
    type: String,
    required: true,
  },
})

// DATA
const products = ref([])
const ready = ref(false)

// COMPUTED
const doctorName = computed(() => {
  return getDoctorName(props.doctor)
})

const savings = computed(() => {
  if (sortedProducts.value.length === 2) {
    const __savings =
      sortedProducts.value[1].prices.find(p => p.selected).price -
      sortedProducts.value[0].prices.find(p => p.selected).price
    return parseFloat(__savings).toFixed(2)
  } else {
    return 0.0
  }
})

const sortedProducts = computed(() => {
  const __products = cloneDeep(products.value)
  return __products.sort(SortUtil.sortByParam(item => item.prices.find(p => p.selected).price))
})

// METHODS
function addProduct(personId, __product, doctor) {
  const { addProductDoctorToBasket } = useProductPersons()
  loading$.start({ blocking: false })
  const productId = __product.productId
  const selectedOption = __product.prices.find(p => p.selected)
  return addProductDoctorToBasket(personId, productId, doctor)
    .then(basket => selectProductOption({ personId, productId, option: selectedOption, __basket: basket }))
    .then(basket => basketStore.updateOvpBasket(basket))
    .then(basket => {
      events$.emit(EVENT_PRODUCT.ADDED, {
        basket,
        personId,
        categoryId: props.categoryId,
        productId,
        source: props.source,
      })
      emit('added')
      loading$.end()
      return basket
    })
    .catch(error => logError(error))
}

function simulate() {
  const { addProductToOvpBasket } = useProductPersons()
  const { getProductsFromGroup } = usePersonSpecificProduct()
  const __products = getProductsFromGroup(props.groupId, props.personId)
  const triggerAmount = __products.length
  let trigger = 0
  // use the same criteria selection for both products
  const selectedOption = props.product.prices.find(p => p.selected)
  __products.forEach(product => {
    addProductToOvpBasket(props.personId, product.productId, { doctor: props.doctor })
      .then(basket =>
        selectProductOption({
          personId: props.personId,
          productId: product.productId,
          option: selectedOption,
          __basket: basket,
        })
      )
      .then(basket => basketStore.updateOvpBasket(basket, { simulate: true }))
      .then(basket => {
        products.value.push(
          Object.assign(
            {},
            { productId: product.productId },
            basket.persons.find(p => p.personId === props.personId).products.products[product.productId]
          )
        )
        trigger++
        if (triggerAmount === trigger) {
          ready.value = true
        }
      })
  })
}

// LIFECYCLE HOOKS
onMounted(() => {
  simulate()

  events$.emit(EVENT_PRODUCT.SUGGESTION_DISPLAYED)
})
</script>

<style name="mobile" scoped>
.suggestion-products {
  display: grid;
  gap: 50px;
}

.suggestion-product {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  .product {
    margin: 8px 0 0 0;
    border: 1px solid var(--c-primary-neutral-1);
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .suggestion-products {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
}
</style>
