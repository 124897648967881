<template>
  <!-- prettier-ignore -->
  <div
    class="product"
    :class="{'product--selected': $props.isSelected}"
    :data-product-id="$props.productId"
  >
    <product-price
      class="product__price"
      :price="options.price"
      :suffix="$t('product.currency')"
    />
    <div class="product__options">
      <the-typography
        tag="span"
        type="bodyMediumLong"
        v-text="productOptions"
      />
      <the-typography
        tag="div"
        type="bodyMediumLong"
        v-text="$props.doctor"
      />
    </div>
    <the-typography
      tag="div"
      type="heading03"
      v-text="$t(`content.products.${$props.productId}.name`)"
    />
    <the-typography
      tag="div"
      type="bodyLargeLong"
      v-html="$t(`content.products.${$props.productId}.description`)"
    />

    <template v-if="!$props.disableBullets">
      <ul class="product-bullets">
        <li
          v-for="(bullet, key) in $t(`content.products.${$props.productId}.bulletPoints`)"
          :key="key"
          v-text="bullet"
        />
      </ul>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import useProduct from '@/hooks/useProduct'

import ProductPrice from '@/components/Product/ProductPrice'

// HOOKS
const { composeOptions, getCategoryIdFromProduct } = useProduct()

// INIT
const props = defineProps({
  disableBullets: {
    type: Boolean,
    default: false,
  },
  doctor: {
    type: String,
    default: null,
  },
  groupId: {
    type: String,
    required: true,
  },
  product: {
    type: Object,
    required: true,
  },
  productId: {
    type: String,
    required: true,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
})

// COMPUTED
const options = computed(() => {
  return props.product.prices.find(p => p.selected)
})

const productOptions = computed(() => {
  const categoryId = getCategoryIdFromProduct(props.productId)
  return composeOptions(Object.assign({}, props.product, { productId: props.productId }), props.groupId, categoryId)
})
</script>

<style name="mobile" scoped>
.product {
  display: grid;
  height: 100%;
  grid-template-rows:
    50px /* price */
    minmax(0, auto) /* criteria */
    45px /* name */
    minmax(0, auto) /* description */
    1fr /* bullets */
    minmax(0, auto) /* actions */;

  padding: 15px;
}

.product {
  &__price {
    font-weight: bold;
    font-size: 36px;
    line-height: 50px;
  }

  &__name {
    font-size: 24px;
    padding: 8px 0 4px 0;
  }

  &__description {
    padding: 0 0 14px 0;
  }

  &-bullets {
    margin: 5px 0 0 0;
    padding: 0 20px 24px 20px;

    li {
      letter-spacing: 0;
      line-height: 20px;
      color: var(--c-primary-neutral-1);
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
    align-items: baseline;

    button {
      margin: 0;
    }
  }

  &--selected {
    background-color: var(--c-primary-color-3-c);
    border-color: transparent !important;
  }
}
</style>

<style name="desktop" scoped>
@media (--md) {
  .product {
    &-actions {
      flex-direction: row;
    }
  }
}
</style>
